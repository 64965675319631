import request from '@/utils/request'

// 查询客户停机保号详情
export function findAgentProductStopOrder(id) {
  return request({
    url: `/agent_product_stop_orders/${id}`,
    method: 'get'
  })
}

// 查询客户停机保号卡号列表
export function findSimCardStopOrders(id, params) {
  return request({
    url: `/agent_product_stop_orders/${id}/sim_card_orders`,
    method: 'get',
    params: params
  })
}

// 导出客户停机保号卡号列表
export function exportSimCardStopOrders(id, data) {
  return request({
    url: `/agent_product_stop_orders/${id}/sim_card_orders/export`,
    method: 'post',
    data
  })
}

