<template>
  <div>
    <a-breadcrumb class="bread-crumb">
      <a-breadcrumb-item>
        <router-link :to="{ name: 'agent_monthly_bill_info', params: { agent_monthly_bill_id: billId }}">
          {{ productStopOrder.month }}
        </router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item> {{ productStopOrder.agents_product_name }}</a-breadcrumb-item>
    </a-breadcrumb>

    <div>
      <show-agent-monthly-bill-order :product-stop-order="productStopOrder" />
    </div>
  </div>
</template>

<script>
import { findAgentProductStopOrder } from '@/api/agent_product_stop_order'

export default {
  name: 'ShowAgentMonthlyBillStop',
  components: {
    ShowAgentMonthlyBillOrder: () => import('@/views/agent_monthly_bills/stop/show/Order')
  },
  data() {
    return {
      productStopOrder: {}
    }
  },
  computed: {
    productStopOrderId() {
      return parseInt(this.$route.params.stop_id)
    },

    billId() {
      return parseInt(this.$route.query.bill_id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findAgentProductStopOrder(this.productStopOrderId).then((res) => {
        if (res.code === 0) {
          this.productStopOrder = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 20px;
}
</style>
